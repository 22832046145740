import './SimList.scss';

import { OrderType, useGA4 } from '@travelwin/core';

import { ANALYTICS_EVENT_TYPES, useAnalytics } from '../../../../analytics';
import EsimCard from '../../../../components/EsimCard';
import { useNavigate } from '../../../../components/Link';

interface Props {
  data: Array<OrderType>;
  isDefaultView?: boolean;
  from?: string;
}

const className = 'c-SimList';

export const SimListOfOneType = ({ data, isDefaultView, from }: Props) => {
  const { trackEvent } = useGA4();
  const navigate = useNavigate();

  const handleViewEsimClick = (order: OrderType) => {
    navigate(`/esim-details/${order.id}`, {
      state: from
        ? {
            from,
          }
        : null,
    });

    trackEvent(ANALYTICS_EVENT_TYPES.EsimDetailsClicked, {
      event_label: order.id,
      transaction_id: order.order_number,
    });
  };
  const analyticsContext = useAnalytics();

  const trackClickedEsimData = (
    name: string,
    remaining_data: number | undefined,
    order_id: string,
    id: string,
  ) => {
    const trackingData = {
      items: [
        {
          item_id: order_id,
          item_name: name,
          affiliation: id,
          quantity: remaining_data,
        },
      ],
    };

    analyticsContext?.trackActivePlansSelectItem(trackingData);
  };

  const containerClass = isDefaultView
    ? `${className}__container`
    : `${className}__extended-container`;

  return (
    <div className={className}>
      <div className={containerClass}>
        {data.map((esim) => {
          return (
            <EsimCard
              key={esim.id}
              order={esim}
              onArrowClick={handleViewEsimClick}
              trackClickedEsimData={trackClickedEsimData}
            />
          );
        })}
      </div>
    </div>
  );
};
