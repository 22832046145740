import './EsimCard.scss';

import { OperatorLogo, OrderType } from '@travelwin/core';
import { useTranslation } from 'react-i18next';

import {
  isActivated,
  isActivatedNotExpired,
  isActivating,
  isExpired,
  isFailed,
  isRefunded,
} from '../../utils';

interface Props {
  order: OrderType;
  onArrowClick: (product: OrderType) => void;
  trackClickedEsimData: (
    name: string,
    remaining_data: number | undefined,
    order_id: string,
    id: string,
  ) => void;
}

const className = 'c-EsimCard';

export const BYTES_IN_GIGABYTE = 1073741824;

const EsimCard = ({ order, onArrowClick, trackClickedEsimData }: Props) => {
  const {
    product_offering: { name, icon_uri },
    initial_data,
    remaining_data,
    order_id,
  } = order.items[0];

  const { t } = useTranslation();

  const getSubtitle = (order: OrderType) => {
    if (isFailed(order)) {
      return t('orders.failed', 'Failed');
    }
    if (isRefunded(order)) {
      return t('orders.refunded', 'Refunded');
    }
    if (isActivating(order)) {
      return t('orders.activating', 'Activating');
    }
    if (!isActivated(order)) {
      return t('orders.inactive', 'Ready to use');
    }
    if (isExpired(order)) {
      return t('orders.expired', 'Expired');
    }
  };

  let progress, dataInGB;
  if (initial_data) {
    if (remaining_data) {
      progress = (remaining_data / initial_data) * 100;
      dataInGB = (remaining_data / BYTES_IN_GIGABYTE).toFixed(2);
    } else {
      progress = 100;
      dataInGB = (initial_data / BYTES_IN_GIGABYTE).toFixed(0);
    }
  }

  const handleArrowClick = (order: any) => {
    trackClickedEsimData(name, remaining_data, order_id, order.retailer.id);
    onArrowClick(order);
  };

  const subtitle = getSubtitle(order);

  return (
    <div className={className}>
      <div
        className={`${className}__body`}
        onClick={() => handleArrowClick(order)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleArrowClick(order);
          }
        }}
        role="button"
        tabIndex={0}
        data-testid="button-esim-card"
      >
        <div className={`${className}__title`}>
          <div className={`${className}__content`}>
            <div className={`${className}__plan-name`}>{name}</div>
            {subtitle ? (
              <div className={`${className}__subtitle`}>{subtitle}</div>
            ) : null}
            {progress ? (
              <div className={`${className}__progress-container`}>
                <div className={`${className}__progress-bar`}>
                  <div
                    className={`${className}__progress`}
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
              </div>
            ) : null}
          </div>
          <div className={`${className}__icon`}>
            <OperatorLogo name={icon_uri} />
          </div>
        </div>
        {isActivatedNotExpired(order) ? (
          <div className={`${className}__stats`}>
            <div className={`${className}__status`}>
              <div className={`${className}__label`}>
                <span className={`${className}__label-text`}>
                  {t('orders.active', 'Active')}
                </span>
              </div>
              {dataInGB ? (
                <div className={`${className}__data`}>
                  {`${dataInGB} GB ${t('orders.remaining', 'Remaining')}`}
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EsimCard;
